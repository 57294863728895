<template>
    <div class="animated fadeIn">
        <b-card>
            <b-card-header>
                <i class="icon-menu mr-1"></i>Data Table Background Image Share
            </b-card-header>
            <b-card-body>
                <background></background>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import background from './Table'
export default {
    name: 'backgrounds',
    components: {
        background,
    },
}
</script>
